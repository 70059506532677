import React, { useEffect, useRef, useState } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import Title from "components/Shared/Title"
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import useFilters from "components/Shared/useFilters"
import {
  commonFilterOptions,
  unitStatusOptions,
} from "components/Common/constants"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Paginations from "components/Common/Pagination"
import TopTitle from "components/Shared/TopTitle"
import TrashIcon from "assets/images/icons/trash.svg"
import EditIcon from "assets/images/icons/edit.svg"
import ViewIcon from "assets/images/icons/view.svg"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
  handleStopPropagation,
} from "components/Common/Utils"
import Switch from "components/Shared/Switch"
import DeleteModal from "components/Common/DeleteModal"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteUnit,
  getAllUnits,
  updateUnitStatus,
} from "store/actions/unitsAction"
import Spinners from "components/Shared/Spinners"
import { getUnitStatus } from "components/Common/StatusUtils"
import { showAlert } from "store/reducers/alertReducer"
import { exportData } from "helpers/api_helper"
import { EXPORT_DATA } from "helpers/url_helper"

const Units = ({ mode }) => {
  // mode = allUnits(For all units), projectUnits(For only single project units)
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Units | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { projectId } = useParams()
  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    units,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.Units)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    status: -1,
    orderBy: 2,
    project: [],
    statusType: [],
  })

  useEffect(() => {
    fetchAllUnits()
  }, [mode])

  const fetchAllUnits = page => {
    const updatedProject = mode === "projectUnits" ? [projectId] : []
    const { orderBy, statusType } = filters
    const updatedStatusType = statusType?.map(item => item.value) || []

    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: -1,
      orderBy,
      keyword,
      project: updatedProject,
      statusType: updatedStatusType,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllUnits(payload))
    setIsApiCalled(true)

    if (mode === "allUnits") {
      navigate(`/units?page=${page || pageNumber}`)
    } else if (mode === "projectUnits") {
      navigate(`/projects/${projectId}/units?page=${page || pageNumber}`)
    }
  }

  const extendedUnitStatusOptions = [
    { value: 0, label: "Disabled" },
    ...unitStatusOptions,
  ]

  const additionOptions = [
    {
      name: "statusType",
      type: "multiSelect",
      options: extendedUnitStatusOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Status"),
    },
  ]

  const filterOptions = [...commonFilterOptions, ...additionOptions]

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllUnits(1)
  }

  const onPageChange = page => {
    fetchAllUnits(page)
  }

  const handleStatusClick = (id, status) => {
    setSelectedId(id)
    const payload = {
      id,
      status: status === 0 ? "1" : "0",
    }
    dispatch(updateUnitStatus(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleDelete = () => {
    const payload = {
      id: itemIdToDelete,
      status: "2", // 2 FOR DELETE
    }
    dispatch(deleteUnit(payload))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  const handleCreateClick = () => {
    if (mode === "allUnits") {
      navigate(`/units/create`)
    } else if (mode === "projectUnits") {
      navigate(`/projects/${projectId}/units/create`)
    }
  }

  const handleUpdateClick = (projectId, unitId) => {
    navigate(`/projects/${projectId}/units/update?id=${unitId}`)
  }

  const handleViewClick = (projectId, unitId) => {
    navigate(`/projects/${projectId}/units/details?id=${unitId}`)
  }

  const getExportFilters = () => {
    const updatedProject = mode === "projectUnits" ? [projectId] : []
    const { statusType } = filters
    const updatedStatusType = statusType?.map(item => item.value) || []

    return {
      status: -1,
      project: updatedProject,
      statusType: updatedStatusType,
      dateRange: getDateRange(dateRange),
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "Unit",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Units"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "Unit",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Units"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content unit_page">
        <Container fluid>
          <TopTitle
            title={t("Units")}
            subTitle={t(
              `Hi, {{name}}. Welcome back to your Marketing portal!`,
              { name: name }
            )}
            createBtnText={t("Create Unit")}
            onCreateBtnClick={handleCreateClick}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Units")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : units.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Unit Name")}</th>
                          <th>{t("Project Name")}</th>
                          <th>{t("Unit Type")}</th>
                          <th>{t("Unit Area")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {units.map((unit, index) => (
                          <tr
                            key={unit._id}
                            className="cursor-pointer"
                            onClick={() => {
                              navigate(
                                `/projects/${projectId}/units/details?id=${unit._id}`
                              )
                            }}
                          >
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>
                              {language === "sa"
                                ? unit.nameAr
                                : unit.name || "-"}
                            </td>
                            <td>
                              {language === "sa"
                                ? unit?.projectId?.nameAr
                                : unit?.projectId?.name || "-"}
                            </td>
                            <td>{t(unit?.generalDetails?.unitType)}</td>
                            <td>{`${unit?.generalDetails?.area ?? "-"} ${
                              t(unit?.generalDetails?.areaUnit)
                            }`}</td>
                            <td>
                              <p
                                className={`${
                                  getUnitStatus(unit.status).className
                                } status_tag`}
                              >
                                {t(getUnitStatus(unit.status).label)}
                              </p>
                            </td>
                            <td onClick={handleStopPropagation}>
                              <div className="d-flex align-items-center">
                                <img
                                  src={EditIcon}
                                  alt="Edit Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleUpdateClick(
                                      unit?.projectId?._id,
                                      unit._id
                                    )
                                  }
                                />
                                <img
                                  src={TrashIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer mx-3"
                                  onClick={() => {
                                    setItemIdToDelete(unit._id)
                                    setShowDeleteModal(true)
                                  }}
                                />
                                <img
                                  src={ViewIcon}
                                  alt="Trash Icon"
                                  width={24}
                                  height={24}
                                  className="cursor-pointer me-3"
                                  onClick={() =>
                                    handleViewClick(
                                      unit?.projectId?._id,
                                      unit._id
                                    )
                                  }
                                />
                                <Switch
                                  onClickHandler={() =>
                                    handleStatusClick(unit._id, unit.status)
                                  }
                                  isChecked={unit.status === 0 ? false : true}
                                  disabled={
                                    isLoading && selectedId === unit._id
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && units.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => {
          setItemIdToDelete(null)
          setShowDeleteModal(false)
        }}
        isLoading={isLoading}
        buttonText={t("Delete")}
        bodyTitle={t("Are you sure you want to delete this {{name}}?", {
          name: t("Unit"),
        })}
      />
    </React.Fragment>
  )
}

export default Units
