import Swal from "sweetalert2"
import store from "../../store"
import i18next from "i18next"

import {
  addMonths,
  differenceInMonths,
  format,
  formatDistanceToNowStrict,
  isValid,
  parseISO,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns"

// GETTING STATE FROM REDUX STORE
// const state = store.getState()

// FUNCTION FOR CHECK ACCESS
const hasAccess = (userType, allowedRoles) => {
  return allowedRoles?.includes(userType)
}

// FOR CHECK AND SET FILE
const handleFileUpload = (event, setFieldValue, fieldName) => {
  const file = event.target.files[0]
  if (file) {
    setFieldValue(fieldName, file)
  }
}

// FOR CHECK AND RETURN FILE
const handleFileUpload2 = event => {
  const file = event.target.files[0]
  if (file) {
    return file
  }
}

const getImagePreview = image => {
  if (typeof image === "string") {
    // If image is a string, assume it's a URL and return it directly
    return image
  } else if (image instanceof Blob) {
    // If image is a Blob or File object, create a preview URL
    return URL.createObjectURL(image)
  }
  return null // If neither, return null
}

// FOR RECTANGLE IMAGE
const handleBannerUpload = (event, setFieldValue, fieldName) => {
  const file = event.target.files[0]

  if (file) {
    const reader = new FileReader()

    reader.onload = e => {
      const img = new Image()
      img.src = e.target.result

      img.onload = () => {
        const width = img.width
        const height = img.height

        if (width > height) {
          // If width is greater than height, it's a rectangle
          setFieldValue(fieldName, file)
        } else {
          Swal.fire({
            toast: true,
            icon: "error",
            title: i18next.t(
              "Only rectangular images (width greater than height) are allowed"
            ),
            position: "top-right",
            showConfirmButton: false,
            timer: 4000,
          })
        }
      }

      img.onerror = () => {
        alert("Failed to load image.")
      }
    }

    reader.readAsDataURL(file)
  }
}

// FOR SQUARE IMAGE
const handleSquareFileUpload = (
  event,
  setFieldValue,
  fieldName,
  isMaxSize = false,
  maxSize = null
) => {
  const file = event.target.files[0]
  if (file) {
    // Check file size in KB
    const fileSizeInKB = file.size / 1024

    if (isMaxSize && fileSizeInKB > maxSize) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: i18next.t(`File size must not exceed ${maxSize} KB`),
        position: "top-right",
        showConfirmButton: false,
        timer: 4000,
      })
      event.target.value = "" // Reset the file input
      return
    }

    const img = new Image()
    img.onload = function () {
      if (this.width === this.height) {
        setFieldValue(fieldName, file)
      } else {
        Swal.fire({
          toast: true,
          icon: "error",
          title: i18next.t("Please upload a square image"),
          position: "top-right",
          showConfirmButton: false,
          timer: 4000,
        })
        event.target.value = ""
      }
    }
    img.onerror = function () {
      alert("Error loading image. Please try again.")
      // Reset the file input
      event.target.value = ""
    }
    img.src = URL.createObjectURL(file)
  }
}

const handle_4_3_FileUpload = (
  event,
  setFieldValue,
  fieldName,
  isMaxSize = false,
  maxSize = null
) => {
  const file = event.target.files[0]
  if (file) {
    // Check file size in KB
    const fileSizeInKB = file.size / 1024

    if (isMaxSize && fileSizeInKB > maxSize) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: i18next.t(`File size must not exceed ${maxSize} KB`),
        position: "top-right",
        showConfirmButton: false,
        timer: 4000,
      })
      event.target.value = "" // Reset the file input
      return
    }

    const img = new Image()
    img.onload = function () {
      // Check if the image has a 4:3 aspect ratio
      const aspectRatio = this.width / this.height
      if (aspectRatio.toFixed(2) === (4 / 3).toFixed(2)) {
        setFieldValue(fieldName, file)
      } else {
        Swal.fire({
          toast: true,
          icon: "error",
          title: i18next.t("Please upload an image with a 4:3 aspect ratio"),
          position: "top-right",
          showConfirmButton: false,
          timer: 4000,
        })
        event.target.value = "" // Reset the file input
      }
    }
    img.onerror = function () {
      alert("Error loading image. Please try again.")
      event.target.value = "" // Reset the file input
    }
    img.src = URL.createObjectURL(file)
  }
}

const handleStopPropagation = e => {
  e.preventDefault
  e.stopPropagation()
}

const handleFileUpload_24_24 = (
  event,
  setFieldValue,
  fieldName,
  isMaxSize = false,
  maxSize = null
) => {
  const file = event.target.files[0]

  if (file) {
    const fileSizeInKB = file.size / 1024

    if (isMaxSize && fileSizeInKB > maxSize) {
      Swal.fire({
        toast: true,
        icon: "error",
        title: i18next.t(`File size must not exceed ${maxSize} KB`),
        position: "top-right",
        showConfirmButton: false,
        timer: 4000,
      })
      event.target.value = "" // Reset the file input
      return
    }

    const img = new Image()

    // Create a URL for the uploaded image
    const objectUrl = URL.createObjectURL(file)

    // Once the image is loaded, we can check its dimensions
    img.onload = () => {
      const { width, height } = img

      if (width === 24 && height === 24) {
        // Image has the correct dimensions
        setFieldValue(fieldName, file)
      } else {
        Swal.fire({
          toast: true,
          icon: "error",
          title: i18next.t("Only 24x24 px images are allowed"),
          position: "top-right",
          showConfirmButton: false,
          timer: 4000,
        })
      }

      // Release the object URL after checking the image size
      URL.revokeObjectURL(objectUrl)
    }

    // Set the image source to trigger the load event
    img.src = objectUrl
  }
}

const formatDate = date => {
  // Check if the date is valid
  if (isValid(date)) {
    return format(date, "dd-MM-yyyy")
  }
  // Return a fallback value or handle the error as needed
  return "Invalid Date" // You can customize this message as needed
}

const getTodayDate = () => {
  return format(new Date(), "dd-MM-yyyy") // Format today's date
}

const getYesterdayDate = () => {
  const yesterday = subDays(new Date(), 1) // Subtract one day to get yesterday
  return format(yesterday, "dd-MM-yyyy") // Format yesterday's date
}

const getCurrentMonth = () => {
  // Returns the start of the current month
  return format(startOfMonth(new Date()), "dd-MM-yyyy")
}

const getLastMonth = () => {
  // Returns the start of the previous month
  const lastMonth = subMonths(new Date(), 1)
  return format(startOfMonth(lastMonth), "dd-MM-yyyy")
}

const getDateRange = dateRange => {
  return dateRange.min && dateRange.max
    ? { min: dateRange.min, max: dateRange.max }
    : {}
}

const getDashboardDateRange = dateRange => {
  if (dateRange.length === 2 && dateRange[0] && dateRange[1]) {
    return {
      min: dateRange[0],
      max: dateRange[1],
    }
  } else {
    return {}
  }
}

const getTimeAgo = date => {
  try {
    const parsedDate = new Date(date)

    // Check if the date is invalid
    if (isNaN(parsedDate.getTime())) {
      return "-"
    }

    return formatDistanceToNowStrict(parsedDate, {
      addSuffix: true,
      roundingMethod: "floor",
    })
  } catch (error) {
    return "-"
  }
}

const fileAccept =
  ".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .rtf, .csv, image/jpeg, image/jpg, image/png, image/webp"

const DownloadCSV = (csvData, fileName) => {
  const blob = new Blob([csvData], { type: "text/csv" })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const xlsxType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

const DownloadXLSX = (xlsxData, fileName) => {
  const blob = new Blob([xlsxData], {
    type: xlsxType,
  })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = url
  link.setAttribute("download", `${fileName}.xlsx`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// DASHBOARD GET MONTH LABELS
export const getMonthLabels = (start, end) => {
  const startMonth = new Date(start)
  const endMonth = new Date(end)
  const labels = []

  // Iterate from start to end month
  for (let d = startMonth; d <= endMonth; d.setMonth(d.getMonth() + 1)) {
    labels.push(
      `${d.toLocaleString("default", { month: "short" })} ${d.getFullYear()}`
    )
  }
  return labels
}

//Convert date in yyyy-mm-dd format
const convertDateFormat = dateStr => {
  // Ensure dateStr is a string
  if (typeof dateStr === "string") {
    const [day, month, year] = dateStr.split("-") // Split the date string
    return `${year}-${month}-${day}` // Return in yyyy-mm-dd format
  }

  // If it's a Date object, convert it to the desired format
  if (dateStr instanceof Date) {
    const day = String(dateStr.getDate()).padStart(2, "0")
    const month = String(dateStr.getMonth() + 1).padStart(2, "0") // Months are zero-based
    const year = dateStr.getFullYear()
    return `${year}-${month}-${day}`
  }

  return dateStr
}

export {
  hasAccess,
  handleFileUpload,
  getImagePreview,
  handleBannerUpload,
  handleStopPropagation,
  handleFileUpload2,
  formatDate,
  getTodayDate,
  getYesterdayDate,
  getDateRange,
  handleSquareFileUpload,
  handle_4_3_FileUpload,
  handleFileUpload_24_24,
  fileAccept,
  DownloadCSV,
  DownloadXLSX,
  getLastMonth,
  getCurrentMonth,
  getDashboardDateRange,
  convertDateFormat,
  getTimeAgo,
}
